import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  IndexTable,
  Select,
  TextField,
  Text,
} from "@shopify/polaris";
import { Checkbox, DatePicker, InputNumber } from "antd";
import "antd/dist/antd.css";

const BoxTable = ({ data, setData, boxes, setBoxes, fieldError }) => {
  
  const [selected, setSelected] = useState({ index: "", data: "" });

  useEffect(() => {

    console.log(data);
    if(data.line_items)
     data.line_items = data.line_items.filter(item => item.fulfillable_quantity);
    setBoxes(
      data.line_items?.map((item) => ({
        ...item,
        box_no: 1,
        grams: item.grams / 1000,
        content_type_selected: "others",
        ManufactureCountryCode: "IN",
        ManufactureCountryName: "INDIA",
        ShipPieceUOM: "PCS",
        description: item.title,
        copy: false,
        ShipPieceCESS: 0,
        fobValue: (
          parseFloat(item?.price) * item?.quantity -
          parseFloat(item?.total_discount)
        ).toFixed(2),
      }))
    );
  }, [data]);

  // options for select menu
  const COMMODITY_OPTIONS = [
    { label: "Others", value: "others" },
    {
      label: "Handicraft Items/Products",
      value: "Handicraft Items/Products",
    },
    { label: "Handloom Products", value: "Handloom Products" },
    { label: "Books/Periodicals", value: "Books/Periodicals" },
    { label: "Leather Footwear", value: "Leather Footwear" },
    { label: "Toys", value: "Toys" },
    {
      label: "Customized Fashion Garments",
      value: "Customized Fashion Garments",
    },
  ];

  const UNIT_OF_MEASURE_OPTIONS = [
    { label: "Bags", value: "BAG" },
    { label: "Bale", value: "BAL" },
    { label: "Bundles", value: "BDL" },
    { label: "Buckles", value: "BKL" },
    { label: "Billion of units", value: "BOU" },
    { label: "Box", value: "BOX" },
    { label: "Bottles", value: "BTL" },
    { label: "Bunches", value: "BUN" },
    { label: "Cans", value: "CAN" },
    { label: "Cubic meters", value: "CBM" },
    { label: "Cubic centimeters", value: "CCM" },
    { label: "Centimeters", value: "CMS" },
    { label: "Cartons", value: "CTN" },
    { label: "Dozens", value: "DOZ" },
    { label: "Drums", value: "DRM" },
    { label: "Great gross", value: "GGK" },
    { label: "Grammes", value: "GMS" },
    { label: "Gross", value: "GRS" },
    { label: "Gross yards", value: "GYD" },
    { label: "Kilograms", value: "KGS" },
    { label: "Kilolitre", value: "KLR" },
    { label: "Kilometre", value: "KME" },
    { label: "Mililitre", value: "MLT" },
    { label: "Meters", value: "MTR" },
    { label: "Metric ton", value: "MTS" },
    { label: "Numbers", value: "NOS" },
    { label: "Packs", value: "PAC" },
    { label: "Pieces", value: "PCS" },
    { label: "Pairs", value: "PRS" },
    { label: "Quintal", value: "QTL" },
    { label: "Rolls", value: "ROL" },
    { label: "Sets", value: "SET" },
    { label: "Square feet", value: "SQF" },
    { label: "Square meters", value: "SQM" },
    { label: "Square Yards", value: "SQY" },
    { label: "Tablets", value: "TBS" },
    { label: "Ten Gross", value: "TGM" },
    { label: "Thousands", value: "THD" },
    { label: "Tonnes", value: "TON" },
    { label: "Tubes", value: "TUB" },
    { label: "Units", value: "UNT" },
    { label: "Yards", value: "YDS" },
  ];

  const MANUFACTURE_COUNTRY_OPTIONS = [
    { label: "INDIA", value: "IN" },
    { label: "ANDORRA", value: "AD" },
    { label: "ARMENIA", value: "AM" },
    { label: "ARGENTINA", value: "AR" },
    { label: "AMERICAN SAMOA", value: "AS" },
    { label: "AUSTRIA", value: "AT" },
    { label: "AUSTRALIA", value: "AU" },
    { label: "AZERBAIJAN", value: "AZ" },
    { label: "BOSNIA AND HERZEGOVINA", value: "BA" },
    { label: "BANGLADESH", value: "BD" },
    { label: "BELGIUM", value: "BE" },
    { label: "BULGARIA", value: "BG" },
    { label: "BRUNEI", value: "BN" },
    { label: "BRAZIL", value: "BR" },
    { label: "BELARUS", value: "BY" },
    { label: "CANADA", value: "CA" },
    { label: "SWITZERLAND", value: "CH" },
    { label: "CHINA PEOPLES REPUBLIC", value: "CN" },
    { label: "COLOMBIA", value: "CO" },
    { label: "CUBA", value: "CU" },
    { label: "CYPRUS", value: "CY" },
    { label: "CZECH REPUBLIC", value: "CZ" },
    { label: "GERMANY", value: "DE" },
    { label: "DENMARK", value: "DK" },
    { label: "ALGERIA", value: "DZ" },
    { label: "ECUADOR", value: "EC" },
    { label: "ESTONIA", value: "EE" },
    { label: "SPAIN", value: "ES" },
    { label: "FINLAND", value: "FI" },
    { label: "MICRONESIA FEDERATED STATES", value: "FM" },
    { label: "FAROE ISLANDS", value: "FO" },
    { label: "FRANCE", value: "FR" },
    { label: "UNITED KINGDOM", value: "GB" },
    { label: "GEORGIA", value: "GE" },
    { label: "FRENCH GUYANA", value: "GF" },
    { label: "GUERNSEY", value: "GG" },
    { label: "GREENLAND", value: "GL" },
    { label: "GUADELOUPE", value: "GP" },
    { label: "GREECE", value: "GR" },
    { label: "GUAM", value: "GU" },
    { label: "CROATIA", value: "HR" },
    { label: "HUNGARY", value: "HU" },
    { label: "CANARY ISLANDS THE", value: "IC" },
    { label: "INDONESIA", value: "ID" },
    { label: "ISRAEL", value: "IL" },
    { label: "ICELAND", value: "IS" },
    { label: "ITALY", value: "IT" },
    { label: "JERSEY", value: "JE" },
    { label: "JAPAN", value: "JP" },
    { label: "KYRGYZSTAN", value: "KG" },
    { label: "CAMBODIA", value: "KH" },
    { label: "KOREA REPUBLIC OF SOUTH K", value: "KR" },
    { label: "KOSOVO", value: "KV" },
    { label: "KAZAKHSTAN", value: "KZ" },
    { label: "LIECHTENSTEIN", value: "LI" },
    { label: "LITHUANIA", value: "LT" },
    { label: "LUXEMBOURG", value: "LU" },
    { label: "LATVIA", value: "LV" },
    { label: "MOROCCO", value: "MA" },
    { label: "MONACO", value: "MC" },
    { label: "MOLDOVA REPUBLIC OF", value: "MD" },
    { label: "MONTENEGRO REPUBLIC OF", value: "MF" },
    { label: "MADAGASCAR", value: "MG" },
    { label: "MARSHALL ISLANDS", value: "MH" },
    { label: "MACEDONIA, REPUBLIC OF", value: "MK" },
    { label: "MONGOLIA", value: "MN" },
    { label: "COMMONWEALTH NO MARIANA ISLANDS", value: "MP" },
    { label: "MARTINIQUE", value: "MQ" },
    { label: "MALDIVES", value: "MV" },
    { label: "MEXICO", value: "MX" },
    { label: "MALAYSIA", value: "MY" },
    { label: "NEW CALEDONIA", value: "NC" },
    { label: "NETHERLANDS", value: "NL" },
    { label: "NORWAY", value: "NO" },
    { label: "NEW ZEALAND", value: "NZ" },
    { label: "PAPUA NEW GUINEA", value: "PG" },
    { label: "PHILIPPINES", value: "PH" },
    { label: "PAKISTAN", value: "PK" },
    { label: "POLAND", value: "PL" },
    { label: "PUERTO RICO", value: "PR" },
    { label: "PORTUGAL", value: "PT" },
    { label: "PALAU", value: "PW" },
    { label: "REUNION ISLAND OF", value: "RE" },
    { label: "ROMANIA", value: "RO" },
    { label: "SERBIA REPUBLIC OF", value: "RS" },
    { label: "RUSSIAN FEDERATION", value: "RU" },
    { label: "SWEDEN", value: "SE" },
    { label: "SINGAPORE", value: "SG" },
    { label: "SAINT HELENA", value: "SH" },
    { label: "SLOVENIA", value: "SI" },
    { label: "SLOVAKIA", value: "SK" },
    { label: "SAN MARINO", value: "SM" },
    { label: "SWAZILAND", value: "SZ" },
    { label: "THAILAND", value: "TH" },
    { label: "TUNISIA", value: "TN" },
    { label: "TURKEY", value: "TR" },
    { label: "TAIWAN", value: "TW" },
    { label: "UKRAINE", value: "UA" },
    { label: "UNITED STATES OF AMERICA", value: "US" },
    { label: "UZBEKISTAN", value: "UZ" },
    { label: "VIRGIN ISLANDS US", value: "VI" },
    { label: "ST BARTHELEMY", value: "XY" },
    { label: "MAYOTTE", value: "YT" },
    { label: "SOUTH AFRICA", value: "ZA" },
  ];

  //   table namex
  const resourceName = {
    singular: "Box",
    plural: "Boxes",
  };

  // box no options
  const box_options = () => {
    let boxes2 = [];

    if (data.pieces) {
      // console.log("pieces" + data.pieces);
      for (let i = 1; i <= data.pieces; i++) {
        boxes2.push({ label: i, value: `${i}` });
      }
    }
    return boxes2;
  };

  const handleAdd = () => {
    if (selected.data.fulfillable_quantity > 1) {
      setBoxes((prev) => {
        const arr = [...prev];
        const data = { ...selected.data, copy: true };
        arr.splice(selected.index + 1, 0, data);
        return arr;
      });
    }

    setSelected({ index: "", data: "" });
  };
  const handleRemove = () => {
    if (selected.data.copy) {
      setBoxes((prev) => {
        const arr = [...prev];
        arr.splice(selected.index, 1);
        return arr;
      });
    }

    setSelected({ index: "", data: "" });
  };

  // field empty error
  const isError = (box, field) => {
    return fieldError[box.id] && fieldError[box.id][field]
      ? "can't be empty"
      : "";
  };

  return (
    <>
      <IndexTable
        resourceName={resourceName}
        itemCount={1}
        selectable={false}
        headings={[
          { title: "" },
          { title: "Box No" },
          { title: "Product Title" },
          { title: "Commodity Type*" },
          { title: "Description*" },
          { title: "License Number" },
          { title: "Expiry Date" },
          { title: "HSN Code*" },
          { title: "Unit Price" },
          { title: "FOB Value*" },
          { title: "Comodity Code" },
          { title: "Weight (kg)*" },
          { title: "Quantity*" },
          { title: "Unit of Measure*" },
          { title: "Manufacture Country*" },
          { title: "Peice IGST Percentage*" },
          { title: "Piece CESS Value*" },
          { title: "-------------------" },
        ]}
      >
        {boxes &&
          boxes.map((item, index) => {
            
            return (
              <IndexTable.Row key={index} id={index} position={index}>
                <IndexTable.Cell>
                  <Checkbox
                    checked={selected.index === index}
                    onChange={() => setSelected({ index: index, data: item })}
                  />
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Select
                    label=""
                    value={`${item.box_no}`}
                    options={box_options()}
                    onChange={(val) =>
                      setBoxes((prev) => {
                        const newArr = [...prev];
                        const modObj = newArr[index];

                        modObj.box_no = val;
                        return newArr;
                      })
                    }
                  ></Select>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <TextField
                    value={item?.title}
                    onChange={(val) =>
                      setBoxes((prev) => {
                        const newArr = [...prev];
                        const modObj = newArr[index];

                        modObj.title = val;
                        return newArr;
                      })
                    }
                    error={isError("title")}
                  />
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Select
                    label=""
                    options={COMMODITY_OPTIONS}
                    value={item.content_type_selected}
                    onChange={(val) =>
                      setBoxes((prev) => {
                        const newArr = [...prev];
                        const modObj = newArr[index];

                        modObj.content_type_selected = val;
                        return newArr;
                      })
                    }
                  ></Select>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <TextField
                    value={item?.description}
                    onChange={(val) =>
                      setBoxes((prev) => {
                        const newArr = [...prev];
                        const modObj = newArr[index];

                        modObj.description = val;
                        return newArr;
                      })
                    }
                    // error={isError("title")}
                  />
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <TextField
                    value={item?.LicenseNumber}
                    onChange={(val) =>
                      setBoxes((prev) => {
                        const newArr = [...prev];
                        const modObj = newArr[index];

                        modObj.LicenseNumber = val;
                        return newArr;
                      })
                    }
                    error={isError("LicenseNumber")}
                  />
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <div style={{ width: "120px" }}>
                    <DatePicker
                      onChange={(val) =>
                        setBoxes((prev) => {
                          const newArr = [...prev];
                          const modObj = newArr[index];

                          modObj.ExpiryDate = val;
                          return newArr;
                        })
                      }
                    />
                  </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <div style={{ width: "100px" }}>
                    <TextField
                      value={item?.hsncode}
                      onChange={(val) =>
                        setBoxes((prev) => {
                          const newArr = [...prev];
                          const modObj = newArr[index];

                          modObj.hsncode = val;
                          return newArr;
                        })
                      }
                      error={isError(item, "hsncode")}
                    />
                  </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <div style={{ width: "100px" }}>
                    <Text>{item?.price}</Text>
                  </div>
                </IndexTable.Cell>
                {/* !field */}
                <IndexTable.Cell>
                  {/* <div style={{ width: "100px" }}>
                    <TextField
                      value={item?.fobValue}
                      onChange={(value) => {
                        const val = parseInt(value);
                        console.log("dis", item?.total_discount);

                        setBoxes((prev) => {
                          const newArr = [...prev];
                          const modObj = newArr[index];

                          if (
                            !isNaN(val) &&
                            val >=
                              parseFloat(item?.price) * item?.quantity -
                                parseFloat(item?.total_discount)
                          ) {
                            modObj.fobValue = val;
                          } else {
                            // modObj.fobValue = fobCalcValue;
                          }

                          return newArr;
                        });
                      }}
                      error={isError(item, "fobValue")}
                    />
                  </div> */}
                  <div style={{ width: "100px" }}>
                    <TextField
                      value={item?.fobValue}
                      onChange={(value) => {
                        const val = value;

                        setBoxes((prev) => {
                          const newArr = [...prev];
                          const modObj = newArr[index];
                          modObj.fobValue = val;
                          return newArr;
                        });
                      }}
                      error={isError(item, "fobValue")}
                    />
                  </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <TextField
                    type="number"
                    value={item?.CommodityCode}
                    onChange={(val) =>
                      setBoxes((prev) => {
                        const newArr = [...prev];
                        const modObj = newArr[index];

                        modObj.CommodityCode = val;
                        return newArr;
                      })
                    }
                    error={isError(item, "CommodityCode")}
                  />
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <TextField
                    value={item?.grams}
                    onChange={(val) =>
                      setBoxes((prev) => {
                        const newArr = [...prev];
                        const modObj = newArr[index];

                        modObj.grams = val;
                        return newArr;
                      })
                    }
                    error={isError("grams")}
                  />
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <div style={{ width: "20px !important" }}>
                    <InputNumber
                      value={item?.fulfillable_quantity}
                      onChange={(val) =>
                        setBoxes((prev) => {
                          const newArr = [...prev];
                          const modObj = newArr[index];

                          modObj.fulfillable_quantity = val;
                          return newArr;
                        })
                      }
                    />
                  </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Select
                    label=""
                    options={UNIT_OF_MEASURE_OPTIONS}
                    value={item.ShipPieceUOM}
                    onChange={(val) =>
                      setBoxes((prev) => {
                        const newArr = [...prev];
                        const modObj = newArr[index];

                        modObj.ShipPieceUOM = val;
                        return newArr;
                      })
                    }
                  ></Select>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Select
                    label=""
                    options={MANUFACTURE_COUNTRY_OPTIONS}
                    value={item?.ManufactureCountryCode}
                    onChange={(val) =>
                      setBoxes((prev) => {
                        const newArr = [...prev];
                        const modObj = newArr[index];

                        modObj.ManufactureCountryName =
                          MANUFACTURE_COUNTRY_OPTIONS.find(
                            (item) => item.value === val
                          ).label;
                        modObj.ManufactureCountryCode = val;
                        return newArr;
                      })
                    }
                  ></Select>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <TextField
                    value={item?.ShipPieceIGSTPercentage}
                    type="number"
                    onChange={(val) =>
                      setBoxes((prev) => {
                        const newArr = [...prev];
                        const modObj = newArr[index];

                        modObj.ShipPieceIGSTPercentage = val;
                        return newArr;
                      })
                    }
                    error={isError(item, "ShipPieceIGSTPercentage")}
                  />
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <TextField
                    value={item?.ShipPieceCESS}
                    type="number"
                    onChange={(val) =>
                      setBoxes((prev) => {
                        const newArr = [...prev];
                        const modObj = newArr[index];
                        modObj.ShipPieceCESS = val;
                        return newArr;
                      })
                    }
                    error={isError(item, "ShipPieceCESS")}
                  />
                </IndexTable.Cell>
              </IndexTable.Row>
            );
          })}
      </IndexTable>
      <div
        style={{ display: "flex", justifyContent: "right", margin: "10px 0px" }}
      >
        {selected.index === "" ? (
          <ButtonGroup>
            <Button small disabled>
              Add
            </Button>

            <Button small disabled>
              Remove
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            <Button small primary onClick={handleAdd}>
              Add
            </Button>

            {selected.data.copy ? (
              <Button small destructive onClick={handleRemove}>
                Remove
              </Button>
            ) : (
              <Button small destructive disabled>
                Remove
              </Button>
            )}
          </ButtonGroup>
        )}
      </div>
    </>
  );
};

export default BoxTable;
